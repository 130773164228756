import {
    LOGIN_USER,
    LOGIN_USER_GOOGLE,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    FORGET_PASSWORD,
    GET_ALL_USERS,
    GET_ALL_USERS_ERROR,
    GET_ALL_USERS_SUCCESS,
    GET_USER_CLAIMS,
    GET_USER_CLAIMS_SUCCESS,
    SHOW_USER_ERROR,
    GET_CURRENTLY_USER,
    LOGIN_USER_ERROR,
    UPDATE_CURRENT_USER,
    SHOW_USER_MESSAGE
} from '../actions';

export const loginUser = (user, history) => ({
    type: LOGIN_USER,
    payload: {user, history}
});

export const getCurrentlyUser = (history) => ({
    type: GET_CURRENTLY_USER,
    payload: {history}
});

export const loginUserGoogle = (history) => ({
    type: LOGIN_USER_GOOGLE,
    payload: {history}
});

export const loginUserSuccess = (user) => ({
    type: LOGIN_USER_SUCCESS,
    payload: user
});

export const loginUserError = (error) => ({
    type: LOGIN_USER_ERROR,
    payload: error
});

export const updateUser = (user) => ({
    type: UPDATE_CURRENT_USER,
    payload: user
});

export const registerUser = (user, history) => ({
    type: REGISTER_USER,
    payload: {user, history}
});

export const registerUserSuccess = () => ({
    type: REGISTER_USER_SUCCESS,
});

export const forgotPassword = (email, history) => ({
    type: FORGET_PASSWORD,
    payload: {email, history}
});

export const logoutUser = (history) => ({
    type: LOGOUT_USER,
    payload: {history}
});

export const getAllUsers = () => ({
    type: GET_ALL_USERS
});

export const getAllUsersSuccess = (users) => ({
    type: GET_ALL_USERS_SUCCESS,
    payload: users
});

export const getAllUsersError = (error) => ({
    type: GET_ALL_USERS_ERROR,
    payload: error
});

export const getUserClaims = (role) => ({
    type: GET_USER_CLAIMS,
    payload: role
});

export const getUserClaimsSuccess = (role) => ({
    type: GET_USER_CLAIMS_SUCCESS,
    payload: role
});

export const setUserError = (error) => ({
    type: SHOW_USER_ERROR,
    payload: error
});

export const setUserMessage = (message) => ({
    type: SHOW_USER_MESSAGE,
    payload: message
});