import React, {Component, Fragment} from "react";
import {withRouter} from 'react-router-dom';
import {getTreasureByUid} from "../../../redux/treasures/saga"
import {GoogleMap, Marker, withGoogleMap, withScriptjs} from "react-google-maps";
import {GOOGLE_API_KEY} from '../../../config';

class TreasurePreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            treasure: null,
            loading: true
        };
    }

    componentDidMount() {
        const {match, history} = this.props;
        history && history.location && history.location.treasure
            ? this.setState({treasure: history.location.treasure, loading: false})
            : match && match.params && match.params.id && getTreasureByUid(match.params.id)
            .then(result => {
                const treasure = result.data();
                if (treasure) {
                    this.setState({treasure, loading: false})
                } else {
                    history.push('/');
                }
            })
            .catch(error => error && history.push('/'))
    }

    getDefaultMapCenter = () => {
        const {newPoint} = this.state;
        const {points} = this.state.treasure;
        if (newPoint) {
            return {lat: newPoint.lat, lng: newPoint.lng}
        }
        if (points && points.length > 0) {
            return {lat: points[0].lat, lng: points[0].lng}
        }
        return {lat: 48.15, lng: 11.61}
    };

    MapWithAMarker = withScriptjs(
        withGoogleMap(props => (
            <GoogleMap
                defaultZoom={10}
                defaultCenter={{lat: 48.15, lng: 11.61}}
                center={this.getDefaultMapCenter()}
                onClick={this.onMapClick}
            >
                {this.state.treasure.points && this.state.treasure.points.map((marker, index) => (
                    <Marker key={index} position={{lat: marker.lat, lng: marker.lng}}/>
                ))}
                {this.state.newPoint &&
                <Marker position={this.state.newPoint}/>}
            </GoogleMap>
        ))
    );

    render() {
        const {loading} = this.state;
        const {history} = this.props;
        return (
            <Fragment>
                {history && history.location && history.location.treasure &&
                <button style={{position: 'absolute', top: 0, left: 0}}
                        onClick={() => history.goBack()}
                >GO BACK</button>}
                <div className="disable-text-selection">
                    {loading
                        ? <div className="loading"/>
                        : <this.MapWithAMarker
                            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                            loadingElement={<div className="map-item"/>}
                            containerElement={<div className="map-item"/>}
                            mapElement={<div className="map-item"/>}
                        />}
                </div>
            </Fragment>
        );
    }
}


export default withRouter(TreasurePreview);
