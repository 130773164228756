import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {firebase} from "../../helpers/Firebase";

import {
    getAllTreasuresSuccess,
    getAllTreasuresError,
    addTreasureSuccess,
    addTreasureError,
    editTreasureError,
    editTreasureSuccess,
    deleteTreasureSuccess,
    deleteTreasureError
} from "./actions"
import {TREASURES_ADD_ITEM, TREASURES_DELETE_ITEM, TREASURES_EDIT_ITEM, TREASURES_GET_LIST} from "../actions";

const getAllTreasuresRequest = async (uid, isAdmin) =>
    await buildSearchQuery(uid, isAdmin)
        .then(response => response)
        .catch(error => error);


const buildSearchQuery = async (uid, isAdmin) => {
    let query = firebase.firestore().collection("treasures");
    if (uid && !isAdmin) {
        query = query.where('userUid', '==', uid);
    }
    return query.get()
};


function* getAllTreasures({payload}) {
    const {isAdmin, uid} = payload;
    try {
        const response = yield call(getAllTreasuresRequest, uid, isAdmin);
        yield put(getAllTreasuresSuccess(response));
    } catch (error) {
        yield put(getAllTreasuresError(error));
    }
}

export const getTreasureByUid = async (uid) =>
    await firebase.firestore().collection("treasures").doc(uid).get();

export const getFacebookUserByUid = async (uid) =>
    await firebase.firestore().collection("telegram_users").doc(uid).get();

export const getTreasureUsers = async (treasureId) =>
    await firebase.firestore().collection("treasures").doc(treasureId).collection("telegram_users").get();

export const getTreasureMessages = async (treasureId, userId) =>
    await firebase.firestore().collection("treasures").doc(treasureId).collection("telegram_users").doc(userId).collection("messages").orderBy('time').get();

export const addTreasureMessages = async (treasureId, userId, message) =>
    await new Promise(function (resolve, reject) {
        const docRef = firebase.firestore().collection("treasures").doc(treasureId).collection("telegram_users").doc(userId).collection("messages").doc();
        docRef.set({...message});
        if (docRef && docRef.id) {
            resolve(docRef.id)
        } else {
            reject("Object not created");
        }
    });

const createTreasureAsync = async (treasure) =>
    await new Promise(function (resolve, reject) {
        const docRef = firebase.firestore().collection("treasures").doc(treasure.uid);
        docRef.set({...treasure});
        if (docRef && docRef.id) {
            resolve(treasure)
        } else {
            reject("Object not created");
        }
    })
        .then(treasure => treasure)
        .catch(error => error);

function* createTreasure({payload}) {
    const {treasure} = payload;
    const {history} = payload;
    try {
        const createdTreasure = yield call(createTreasureAsync, treasure);
        if (!createdTreasure.message) {
            yield put(addTreasureSuccess(createdTreasure));
            history.push('/')
        } else {
            console.log('Create failed:', createdTreasure.message)
        }
    } catch (error) {
        yield put(addTreasureError(error));
        console.log('Create failed: ', error)
    }
}

const editTreasureAsync = async (treasure) =>
    await new Promise(function (resolve, reject) {
        const docRef = firebase.firestore().collection("treasures").doc(treasure.uid);
        docRef.update({...treasure});
        if (docRef && docRef.id) {
            resolve(treasure)
        } else {
            reject("Object not created");
        }
    })
        .then(treasure => treasure)
        .catch(error => error);

function* editTreasure({payload}) {
    const {treasure, history} = payload;
    try {
        const updatedTreasure = yield call(editTreasureAsync, treasure);
        if (!updatedTreasure.message) {
            yield put(editTreasureSuccess(updatedTreasure));
            history.push('/')
        } else {
            console.log('Create failed:', updatedTreasure.message)
        }
    } catch (error) {
        yield put(editTreasureError(error));
        console.log('Create failed: ', error)
    }
}

const deleteTreasureAsync = async (uid) =>
    await firebase.firestore().collection("treasures").doc(uid).delete();

function* deleteTreasure({payload}) {
    const {uid, history} = payload;
    try {
        const deleteTreasure = yield call(deleteTreasureAsync, uid);
        if (!deleteTreasure) {
            yield put(deleteTreasureSuccess(deleteTreasure));
            history.push('/')
        } else {
            console.log('Create failed:', deleteTreasure.message)
        }
    } catch (error) {
        yield put(deleteTreasureError(error));
        console.log('Create failed: ', error)
    }
}

export const uploadFileRequest = async (folderName, fileName, file) =>
    await firebase.storage().ref(folderName + '/' + fileName).put(file);

export const getDownloadUrlFile = async (ref) =>
    await ref.getDownloadURL();

export function* watchDeleteTreasure() {
    yield takeEvery(TREASURES_DELETE_ITEM, deleteTreasure);
}

export function* watchEditTreasure() {
    yield takeEvery(TREASURES_EDIT_ITEM, editTreasure);
}

export function* watchCreateTreasure() {
    yield takeEvery(TREASURES_ADD_ITEM, createTreasure);
}

export function* watchGetAllTreasures() {
    yield takeEvery(TREASURES_GET_LIST, getAllTreasures);
}

export default function* rootSaga() {
    yield all([
        fork(watchCreateTreasure),
        fork(watchGetAllTreasures),
        fork(watchEditTreasure),
        fork(watchDeleteTreasure)
    ]);
}
