import React from "react";
import {Card} from "reactstrap";
import {NavLink} from "react-router-dom";
import {Colxx} from "../../components/common/CustomBootstrap";
import {defaultImage} from "../../constants/defaultValues"

const ThumbListView = ({treasure}) => {
    return (
        <Colxx xxs="12" key={treasure.uid} className="mb-3">
            <Card
                className="d-flex flex-row"
            >
                <NavLink to={`?p=${treasure.id}`} className="d-flex">
                    <img
                        alt={treasure.title}
                        src={treasure.image ? treasure.image : defaultImage}
                        className="list-thumbnail responsive border-0"
                    />
                </NavLink>
                <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                    <div
                        className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-start min-width-zero align-items-lg-center">
                        <NavLink to={`${treasure.uid}`} className="w-40 w-sm-100">
                            <p className="list-item-heading mb-1 truncate">
                                {treasure.title}
                            </p>
                        </NavLink>
                        <p className="mb-1 text-muted text-small w-15 w-sm-100">
                            {treasure.info}
                        </p>
                    </div>
                </div>
            </Card>
        </Colxx>
    );
};

export default React.memo(ThumbListView);
