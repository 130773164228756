import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PublicLayout from "../../layout/PublicLayout";

import TreasurePreview from "./treasure";

const Public = ({ match }) => {
    return (
        <PublicLayout>
            <Switch>
                <Route path={`${match.url}/:id`} component={TreasurePreview} />
                <Redirect to="/error" />
            </Switch>
        </PublicLayout>
    );
};

export default Public;
