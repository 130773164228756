import React, {Component} from "react";
import {connect} from "react-redux";
import {
    Route,
    Switch,
    Redirect,
    withRouter
} from "react-router-dom";
import {IntlProvider} from "react-intl";
import AppLocale from "./lang";
import NotificationContainer from "./components/common/react-notifications/NotificationContainer";
import main from "./views";
import app from "./views/app";
import user from "./views/user";
import error from "./views/error";
import publicPage from "./views/public";
import {getDirection} from "./helpers/Utils";
import {getCurrentlyUser} from "./redux/auth/actions"

const AuthRoute = ({component: Component, authUser, ...rest}) => (
    <Route
        {...rest}
        render={props =>
            authUser ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: "/user/login",
                        state: {from: props.location}
                    }}
                />
            )
        }
    />
);

class App extends Component {

    componentDidMount() {
        const {getCurrentlyUser, history} = this.props;
        getCurrentlyUser(history);
    }

    render() {
        const {locale, loginUser, loading} = this.props;
        const currentAppLocale = AppLocale[locale];

        return (
            <div className="h-100">
                <IntlProvider
                    locale={currentAppLocale.locale}
                    messages={currentAppLocale.messages}
                >
                    <React.Fragment>
                        <NotificationContainer/>
                        {loading ? <div className="loading"/> : <Switch>
                            <AuthRoute path="/app" authUser={loginUser} component={app}/>
                            <Route path="/map" component={publicPage}/>
                            <Route path="/user" component={user}/>
                            <Route path="/error" exact component={error}/>
                            <Route path="/" exact component={main}/>
                            <Redirect to="/error"/>
                        </Switch>}
                    </React.Fragment>
                </IntlProvider>
            </div>
        );
    }
}

const mapStateToProps = ({authUser, settings}) => {
    const {user: loginUser, loading} = authUser;
    const {locale} = settings;
    return {loginUser, locale, loading};
};

const mapActionsToProps = {
    getCurrentlyUser,
};

export default withRouter(connect(
    mapStateToProps,
    mapActionsToProps
)(App));
