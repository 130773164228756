
/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_GOOGLE = "LOGIN_USER_GOOGLE";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";

export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const LOGOUT_USER = "LOGOUT_USER";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const UPDATE_USERS_ROLE = "UPDATE_USERS_ROLE";

export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_ERROR = "GET_ALL_USERS_DELETE";
export const SHOW_USER_ERROR = "SHOW_USER_ERROR";
export const SHOW_USER_MESSAGE = "SHOW_USER_MESSAGE";
export const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS = "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

/* TREASURES */
export const TREASURES_GET_LIST = "TREASURES_GET_LIST";
export const TREASURES_GET_LIST_SUCCESS = "TREASURES_GET_LIST_SUCCESS";
export const TREASURES_GET_LIST_ERROR = "TREASURES_GET_LIST_ERROR";

export const TREASURES_ADD_ITEM = "TREASURES_ADD_ITEM";
export const TREASURES_ADD_ITEM_SUCCESS = "TREASURES_ADD_ITEM_SUCCESS";
export const TREASURES_ADD_ITEM_ERROR = "TREASURES_ADD_ITEM_ERROR";

export const TREASURES_EDIT_ITEM = "TREASURES_EDIT_ITEM";
export const TREASURES_EDIT_ITEM_SUCCESS = "TREASURES_EDIT_ITEM_SUCCESS";
export const TREASURES_EDIT_ITEM_ERROR = "TREASURES_EDIT_ITEM_ERROR";

export const TREASURES_DELETE_ITEM = "TREASURES_DELETE_ITEM";
export const TREASURES_DELETE_ITEM_SUCCESS = "TREASURES_DELETE_ITEM_SUCCESS";
export const TREASURES_DELETE_ITEM_ERROR = "TREASURES_DELETE_ITEM_ERROR";

export const GET_CURRENTLY_USER = "GET_CURRENTLY_USER";
export const GET_USER_CLAIMS = "GET_USER_CLAIMS";
export const GET_USER_CLAIMS_SUCCESS = "GET_USER_CLAIMS_SUCCESS";


export * from "./menu/actions";
export * from "./auth/actions";
export * from "./settings/actions";
export * from "./treasures/actions"
