import React, {Fragment} from "react";
import {Card, CardBody} from "reactstrap";
import moment from "moment";
import {GOOGLE_API_KEY} from '../../config';



const MessageCard = ({sender, item, currentUser}) => {

    const questionRender = (json, data) => {
        const reply_markup = JSON.parse(json);
        if(reply_markup.inline_keyboard){
            return reply_markup.inline_keyboard.map(row => {
                return row.map((button, index) => {
                    if(data){
                        return button.callback_data === data ? <span className="message-answer" key={index}>{button.text}</span> : ""
                    } else {
                        return <span className="message-answer" key={index}>{button.text}</span>
                    }
                });
            });
        }


    };

    return (
        <Fragment>
            <Card
                className={`d-inline-block mb-3 float-${
                    item.sender === "user" ? "left" : "right"
                }`}
            >
                <div className="position-absolute  pt-1 pr-2 r-0">
                    <span
                        className="text-extra-small text-muted">{moment(new Date(item.time.seconds ? (item.time.seconds * 1000) : item.time)).format("HH:mm:ss MM/DD/YYYY")}</span>
                </div>
                <CardBody>
                    <div className="d-flex flex-row pb-1">
                        <img
                            alt={sender.first_name}
                            src={item.sender === "user" ? sender.picture : item.sender === "Admin" ? '/assets/img/profile-pic-l-3.jpg' : 'https://firebasestorage.googleapis.com/v0/b/treasurehunt-84462.appspot.com/o/default%2Fbot.jpg?alt=media&token=08365c62-3fb2-4481-8fdb-941a53e0b574'}
                            className="img-thumbnail border-0 rounded-circle mr-3 list-thumbnail align-self-center xsmall"
                        />
                        <div className=" d-flex flex-grow-1 min-width-zero">
                            <div
                                className="m-2 pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                                <div className="min-width-zero">
                                    <p className="mb-0 truncate list-item-heading">
                                        {item.sender === "user" ? `${sender.first_name} ${sender.last_name}` : item.sender === "Admin" ? currentUser.name : 'Bot'}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="chat-text-left d-flex flex-column">
                        {item && item.text &&
                        <p className="mb-0 text-semi-muted">{item.text}</p>}
                        {item && item.options && item.options.reply_markup &&
                        <p className="mb-0 text-semi-muted d-flex flex-column">
                            <div>{item.question}</div>
                            <div
                                className="d-flex flex-column">{questionRender(item.options.reply_markup, item.options.data)}</div>
                        </p>}
                        {item && item.options && item.options.location &&
                        <p className="mb-0 text-semi-muted" style={{maxWidth: '600px'}}>
                            <div style={{display: "flex", flexWrap: 'wrap', position: 'relative', width: '100%'}}>
                                <img style={{height: "auto", width: '100%', marginBottom: '10px'}} src={`https://maps.googleapis.com/maps/api/staticmap?center=${item.options.location.latitude},${item.options.location.longitude}&zoom=16&size=700x320&maptype=roadmap&markers=color:red%7C${item.options.location.latitude},${item.options.location.longitude}&key=${GOOGLE_API_KEY}`}/>
                            </div>
                        </p>
                        }
                        {item.options && item.options.url &&
                        <p className="mb-0 text-semi-muted" style={{maxWidth: '600px'}}>
                            <div style={{display: "flex", flexWrap: 'wrap', position: 'relative', width: '100%'}}>
                                <img style={{height: "auto", width: '100%', marginBottom: '10px'}} src={item.options.url}/>
                            </div>
                        </p>}
                    </div>
                </CardBody>
            </Card>
            <div className="clearfix"/>
        </Fragment>
    );
};

export default MessageCard;
