import React, {Component} from "react";
import {Route, Switch, Redirect, withRouter} from "react-router-dom";
import UserLayout from "../../layout/UserLayout";

import Login from "./login";
import register from "./register";
import forgotPassword from "./forgot-password";
import {verifyPasswordResetCode, applyActionCode} from "../../redux/auth/saga";
import {connect} from "react-redux";

class User extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dto: {
                email: "",
                password: ""
            },
            code: null,
            loading: true
        };
    }

    componentDidMount() {
        const {loginUser, history} = this.props;
        loginUser && history.push('/');

        if (window.location.search) {
            const code = window.location.search.split('&')[1].replace('oobCode=', '');
            const mode = window.location.search.split('&')[0].replace('?mode=', '');
            switch (mode) {
                case 'resetPassword':
                    verifyPasswordResetCode(code).then(res => {
                        if (res) {
                            const {match, history} = this.props;
                            this.setState({loading: false});
                            history.push({
                                pathname: `${match.url}/forgot-password`,
                                code: code,
                                email: res,
                            });
                        } else {
                            this.setState({loading: false});
                        }
                    });
                    break;
                case 'verifyEmail':
                    applyActionCode(code).then(res => {
                        this.setState({loading: false});
                    }).catch(error => error && this.setState({loading: false}));
                    break;
                default:
                    this.setState({loading: false});
                    break;
            }
        } else {
            this.setState({loading: false});
        }
    }

    render() {
        const {match} = this.props;
        const {loading} = this.state;
        return (
            <UserLayout>
                {loading
                    ? <div className="loading"/>
                    : <Switch>
                        <Route path={`${match.url}/login`} component={Login}/>
                        <Route path={`${match.url}/register`} component={register}/>
                        <Route path={`${match.url}/forgot-password`} component={forgotPassword}/>
                        <Route exact from={`${match.url}/`} render={() => <Login/>}/>
                        <Redirect to="/error"/>
                    </Switch>}
            </UserLayout>
        );
    };
}

const mapStateToProps = ({authUser}) => {
    const {user: loginUser} = authUser;
    return {loginUser};
};

export default withRouter(connect(
    mapStateToProps,
    {}
)(User));

