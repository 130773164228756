import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {firebase} from '../../helpers/Firebase';

import {
    GET_ALL_USERS,
} from '../actions';

import {
    getAllUsersSuccess,
    getAllUsersError,
    updateUsersRole
} from './actions';

export const createUserCloud = (user) => {
    const createUser = firebase.functions().httpsCallable('registerUser');
    return createUser(user);
};

export const updateUserCloud = (user) => {
    const updateUser = firebase.functions().httpsCallable('updateUser');
    return updateUser(user)
};

export const deleteUserCloud = (uid) => {
    const updateUser = firebase.functions().httpsCallable('deleteUser');
    return updateUser(uid)
};


const getUsersAllCloud = async () =>
    await firebase.functions().httpsCallable('getUserList')({})
        .then(response => response.data)
        .catch(error => error);


const getAllUsersAsync = async () =>
    await firebase.firestore().collection("users").get()
        .then(response => response)
        .catch(error => error);

function* getAllUsers() {
    const users = [];
    try {
        const response = yield call(getAllUsersAsync);
        response.forEach(doc => {
            const user = doc.data();
            user.uid = doc.ref.id;
            users.push(user);
        });

        yield put(getAllUsersSuccess(users));
        const info = yield call (getUsersAllCloud);

        const UsersWithClaim = users.map((user) => {
            const userDetails = info.find(userDetails => {
                if (userDetails.uid === user.uid){
                    return userDetails
                }
            });
            if (userDetails)  user.claime = userDetails.customClaims ;
            return user
        });
        yield put(updateUsersRole(UsersWithClaim));

    } catch (error) {
        yield put(getAllUsersError(error));
    }
}

export function* watchGetAllUsers() {
    yield takeEvery(GET_ALL_USERS, getAllUsers);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetAllUsers)
    ]);
}
