import React, {Component} from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label, Form,
} from "reactstrap";
import "rc-switch/assets/index.css";
import IntlMessages from "../../helpers/IntlMessages";
import {withRouter} from "react-router-dom";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {updateUser} from "../../redux/auth/actions";
import {updateCurrentUser, reAuthenticate, updatePassword} from "../../redux/auth/saga";
import {createUserCloud, updateUserCloud} from "../../redux/accounts/saga";

class AccountEditModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                name: "",
                currentPassword: "",
                newPassword: "",
            },
            error: "",
            uid: null,
            loading: false,
        }
    }

    componentDidMount() {
        const {user} = this.props;
        user && this.setState({
            user: {
                name: user.name,
                currentPassword: '',
                newPassword: ''
            },
            uid: user.uid,
            loading: false,
        })
    }

    onInputChange = event => {
        const {user} = this.state;
        user[event.target.name] = event.target.value;
        this.setState({user, error: ''});
    };

    onSubmitClick = () => {
        this.setState({loading: true});
        const {updateUser, toggleModal, isAdmin} = this.props;
        const {user, uid} = this.state;

        updateCurrentUser(uid, {name: user.name}).then(result => {
            if (result) {
                const currentUser = this.props.user;
                currentUser.name = user.name;
                updateUser(currentUser);
                if (user.currentPassword === user.newPassword && user.currentPassword.length > 0) {
                    updateUserCloud({password: user.newPassword, admin: isAdmin, ...currentUser})
                        .then(res => res.data.errorInfo
                            ? this.setState({error: res.data.errorInfo.message, loading: false})
                            : this.setState({loading: false}, () => toggleModal()));
                } else {
                    user.currentPassword.length > 0 || user.newPassword.length > 0
                        ? this.setState({error: 'Please make sure your passwords match.', loading: false})
                        : this.setState({loading: false}, () => toggleModal());
                }
            }
        });
    };

    render() {
        const {modalOpen, toggleModal} = this.props;
        const {name, currentPassword, newPassword} = this.state.user;
        const {loading, error} = this.state;
        return (
            <Modal
                isOpen={modalOpen}
                toggle={toggleModal}
                wrapClassName="modal-right"
                backdrop="static"
            >
                <ModalHeader toggle={toggleModal}>
                    Account Settings
                </ModalHeader>
                {loading
                    ? <ModalBody>
                        <div className="loading"/>
                    </ModalBody>
                    : <ModalBody>
                        <Label className="form-group has-float-label">
                            <Input type="text" name="name" value={name}
                                   onChange={event => this.onInputChange(event)}/>
                            <IntlMessages id="account.name"/>
                        </Label>
                        <Label className="form-group has-float-label">
                            <Input type="text" name="newPassword" value={newPassword}
                                   onChange={event => this.onInputChange(event)}/>
                            <IntlMessages id="account.password.new"/>
                        </Label>
                        <Label className="form-group has-float-label">
                            <Input type="text" name="currentPassword" value={currentPassword}
                                   onChange={event => this.onInputChange(event)}/>
                            <IntlMessages id="account.password.confirm"/>
                        </Label>
                        <div className="user-login-error w-100 my-3 text-center">
                            {error}
                        </div>
                    </ModalBody>}
                <ModalFooter>
                    <Button color="secondary" outline onClick={toggleModal} disabled={loading}>
                        <IntlMessages id="pages.cancel"/>
                    </Button>
                    <Button color="primary" onClick={() => this.onSubmitClick()} disabled={loading}>
                        <IntlMessages id="pages.submit"/>
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = ({authUser}) => {
    const {user, isAdmin} = authUser;
    return {
        user, isAdmin
    };
};

export default withRouter(
    injectIntl(
        connect(
            mapStateToProps,
            {
                updateUser
            }
        )(AccountEditModal)
    ));

