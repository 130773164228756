import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {
    Row,
    Button,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    ButtonDropdown,
    Card,
    CardBody,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Input,
    InputGroup,
    CustomInput,
    ModalFooter,
    InputGroupAddon
} from "reactstrap";

import {Colxx, Separator} from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import {GOOGLE_API_KEY} from '../../../config';

import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
} from "react-google-maps";


import Tickets from "../../../containers/treasures/Tickets";
import IntlMessages from "../../../helpers/IntlMessages";
import AddNewSurveyModal from "../../../containers/treasures/AddNewSurveyModal";

import {
    addTreasure,
    editTreasure,
    deleteTreasure,
} from "../../../redux/actions";

import {
    uploadFileRequest,
    getDownloadUrlFile,
    getFacebookUserByUid,
    getTreasureMessages, addTreasureMessages
} from "../../../redux/treasures/saga"

import {randomKey} from "../../../helpers/Utils"
import {getTreasureByUid, getTreasureUsers} from "../../../redux/treasures/saga";
import {withRouter} from "react-router-dom";
import DeleteTreasureModal from "../../../components/treasures/DeleteTreasureModal/DeleteTreasureModal";
import {defaultImage, defaultMapCenter} from "../../../constants/defaultValues";
import PublicTopNav from "../../../containers/navs/PublicTopnav";
import ChatApp from "./chat";
import {sendTextMessage} from "../../../helpers/Telegram";
import QRTreasures from "../../../components/treasures/QRTreasures";

class TreasureDetails extends Component {
    constructor(props) {
        super(props);
        this.inputElement = React.createRef();
        this.state = {
            treasure: {
                uid: randomKey(20),
                userUid: this.props.user.uid,
                title: "",
                image: "",
                info: "",
                greetingMessage: "",
                goodbyeMessage: "",
                points: [],
                expirationTime: 60,
            },
            dropdownSplitOpen: false,
            modalOpen: false,
            editModalOpen: Boolean(!this.props.match.params.id),
            deleteModalOpen: false,
            newPoint: null,
            editPoint: null,
            loading: false,
            forceClick: false,
            previewOpen: false,
            activeUser: null,
            activeTab: "points",
            mapCenter: defaultMapCenter
        };
    }

    componentDidMount() {
        const {match} = this.props;
        match && match.params.id && getTreasureByUid(match.params.id).then(result => {
            const treasure = result.data();
            if (treasure) {
                getTreasureUsers(match.params.id).then(res => {
                    if (res.size) {
                        treasure.users = [];
                        res.forEach(doc => {
                            const info = doc.data();
                            info.id = doc.ref.id;
                            getFacebookUserByUid(info.id).then(res => {
                                const user = res.data();
                                if(user){
                                    info.first_name = user.first_name;
                                    info.gender = user.gender;
                                    info.last_name = user.last_name;
                                    info.locale = user.locale;
                                    info.picture = user.picture;
                                }
                                treasure.users.push(info);
                                this.setState({treasure, mapCenter: treasure.points[0] || defaultMapCenter}, () => treasure.users.length > 0 && this.onChatUserClick(treasure.users[0]))
                            });
                        });
                    } else {
                        this.setState({treasure, mapCenter: treasure.points[0] || defaultMapCenter})
                    }

                });
            } else {
                this.props.history.push('/app/treasures/create');
            }
        });
    }

    MapWithAMarker = withScriptjs(
        withGoogleMap(props => (
            <GoogleMap
                defaultZoom={10}
                defaultCenter={this.state.mapCenter}
                center={this.state.mapCenter}
                onClick={this.onMapClick}
            >
                {this.state.treasure.points && this.state.treasure.points.map((marker, index) => (
                    <Marker key={index} position={{lat: marker.lat, lng: marker.lng}}
                            onClick={() => this.onMarkerClick(marker)}/>
                ))}
                {this.state.newPoint &&
                <Marker position={this.state.newPoint}/>}
            </GoogleMap>
        ))
    );

    onMapClick = event => {
        const {latLng} = event;
        const newPoint = {
            lat: latLng.lat(),
            lng: latLng.lng(),
        };
        this.setState({newPoint}, () => this.toggleModal())
    };

    onMarkerClick = (marker) => {
        this.setState({editPoint: marker}, () => this.toggleModal());
    };

    onChatUserClick = (user, index) => {
        const {treasure} = this.state;
        getTreasureMessages(treasure.uid, user.id).then(res => {
            user.messages = [];
            res && res.forEach(message => {
                user.messages.push(message.data())
            });
            this.setState({activeUser: user})
        });
    };

    onInputChange = event => {
        const {treasure} = this.state;
        treasure[event.target.name] = event.target.value;
        this.setState({treasure});
    };

    toggleSplit = () => {
        this.setState(prevState => ({
            dropdownSplitOpen: !prevState.dropdownSplitOpen
        }));
    };

    previewModal = () => {
        this.setState({
            previewOpen: !this.state.previewOpen,
        });
    };

    toggleModal = () => {
        const {modalOpen} = this.state;
        this.setState({
            newPoint: modalOpen === true ? null : this.state.newPoint,
            editPoint: modalOpen === true ? null : this.state.editPoint,
            modalOpen: !this.state.modalOpen,
        });
    };

    toggleEditModal = () => {
        this.setState({
            editModalOpen: !this.state.editModalOpen,
        });
    };

    toggleDelete = () => {
        this.setState({
            deleteModalOpen: !this.state.deleteModalOpen,
        });
    };

    createTreasure = () => {
        const {treasure} = this.state;
        const {addTreasure, history} = this.props;
        treasure.title.length > 0
            ? addTreasure(treasure, history)
            : this.setState({forceClick: true}, () => this.toggleEditModal())
    };

    editTreasure = () => {
        const {treasure} = this.state;
        const {editTreasure, history} = this.props;
        treasure.title.length > 0
            ? editTreasure(treasure, history)
            : this.setState({forceClick: true}, () => this.toggleEditModal())
    };

    deleteTreasure = () => {
        const {treasure} = this.state;
        const {deleteTreasure, history} = this.props;
        deleteTreasure(treasure.uid, history);
    };

    onInputClick = () => {
        this.inputElement && this.inputElement.current.click();
    };

    addNewPoint = point => {
        const {treasure, newPoint} = this.state;
        point.lat = newPoint.lat;
        point.lng = newPoint.lng;
        treasure.points.push(point);
        this.setState({treasure}, () => this.toggleModal());
    };

    editPoint = point => {
        const {treasure} = this.state;
        const index = treasure.points.findIndex(x => x.id === point.id);
        treasure.points[index] = point;
        this.setState({treasure, editPoint: null}, () => this.toggleModal());
    };

    deletePoint = point => {
        const {treasure} = this.state;
        const index = treasure.points.findIndex(x => x.id === point.id);
        treasure.points.splice(index, 1);
        this.setState({treasure, editPoint: null}, () => this.toggleModal());
    };

    onImageUpload = (event) => {
        this.setState({loading: true});
        const {treasure} = this.state;
        let file = event.target.files[0];
        uploadFileRequest(`treasures/${treasure.uid}/`, file.name, file).then(ref => {
            ref
                ? getDownloadUrlFile(ref.ref).then(url => {
                    if (url) {
                        treasure.image = url;
                        this.setState({treasure, loading: false})
                    } else {
                        this.setState({loading: true});
                    }
                })
                : this.setState({loading: true});
        });
    };

    sendMessage = (message) => {
        const {treasure} = this.state;
        const activeUser = {...this.state.activeUser};
        sendTextMessage(activeUser.id, message).then(res => {
            if (res) {
                const dto = {
                    sender: 'Admin',
                    time: new Date(),
                    text: message,
                };
                addTreasureMessages(treasure.uid, activeUser.id, dto).then(result => {
                    if (result) {
                        activeUser.messages.push(dto);
                        this.setState({activeUser});
                    }
                });

            }
        })
    };

    setActiveTab = activeTab => {
        this.setState({activeTab})
    };

    updateMessage = () => {
        const {treasure, activeUser} = this.state;
        const user = {...activeUser};
        getTreasureMessages(treasure.uid, activeUser.id).then(res => {
            user.messages = [];
            res && res.forEach(message => {
                user.messages.push(message.data())
            });
            this.setState({activeUser: user})
        });
    };

    render() {
        const {loading, modalOpen, editPoint, deleteModalOpen, editModalOpen, dropdownSplitOpen, treasure, forceClick, previewOpen, activeUser, activeTab, mapCenter} = this.state;
        const {match} = this.props;
        const {image, title, info, greetingMessage, goodbyeMessage, points, expirationTime} = treasure;
        return (
            <Fragment>
                <Row>
                    <Colxx xxs="12">
                        <h1>
                            <IntlMessages id="treasures.treasure"/>
                        </h1>
                        <Breadcrumb match={match}/>
                        <div className="text-zero top-right-button-container">
                            <ButtonDropdown
                                className="top-right-button top-right-button-single"
                                isOpen={dropdownSplitOpen}
                                toggle={this.toggleSplit}
                            >
                                <Button
                                    outline
                                    className="flex-grow-1"
                                    size="lg"
                                    color="primary"
                                    onClick={match && match.params.id ? this.editTreasure : this.createTreasure}
                                >
                                    {match && match.params.id ? `SAVE` : `CREATE`}
                                </Button>
                                <DropdownToggle
                                    size="lg"
                                    className="dropdown-toggle-split btn-lg"
                                    caret
                                    outline
                                    color="primary" Survey
                                />
                                <DropdownMenu right>
                                    <DropdownItem onClick={() => this.previewModal()}>
                                        <IntlMessages id="survey.preview"/>
                                    </DropdownItem>
                                    <DropdownItem onClick={() => this.toggleEditModal()}>
                                        <IntlMessages id="survey.edit"/>
                                    </DropdownItem>
                                    <DropdownItem onClick={() => this.toggleDelete()}>
                                        <IntlMessages id="survey.delete"/>
                                    </DropdownItem>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </div>
                        <Separator className="mb-5"/>
                    </Colxx>
                </Row>
                <Row>
                    <Colxx lg="8" md="6" className="mb-4">
                        {activeTab === 'points' && <Card className="mb-4">
                            <CardBody>
                                <this.MapWithAMarker
                                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                                    loadingElement={<div className="map-item"/>}
                                    containerElement={<div className="map-item"/>}
                                    mapElement={<div className="map-item"/>}
                                    mapCenter={mapCenter}
                                />
                            </CardBody>
                        </Card>}
                        {activeUser && activeTab === 'users' &&
                        <ChatApp user={activeUser} update={this.updateMessage} sendMessage={this.sendMessage}/>}
                    </Colxx>
                    <Colxx lg="4" md="6" className="mb-4">
                        <Tickets
                            points={points}
                            onMarkerClick={this.onMarkerClick}
                            activeTab={activeTab}
                            users={treasure.users}
                            treasureLength={treasure.points.length}
                            onUserClick={this.onChatUserClick}
                            setActiveTab={this.setActiveTab}
                        />
                        <QRTreasures treasureId={treasure.uid}/>
                    </Colxx>
                </Row>
                <Modal
                    isOpen={editModalOpen}
                    toggle={this.toggleEditModal}
                    wrapClassName="modal-right"
                    backdrop="static"
                >
                    <ModalHeader toggle={this.toggleEditModal}>
                        <IntlMessages id={match && match.params.id ? "treasure.edit" : "treasure.create"}/>
                    </ModalHeader>
                    <ModalBody>
                        <div className="position-relative d-flex justify-content-center align-items-center">
                            <div className="position-absolute card-top-buttons">
                                <Button outline color={"white"} className="icon-button"
                                        onClick={() => this.onInputClick()}>
                                    <i className="simple-icon-pencil"/>
                                </Button>
                            </div>
                            {loading
                                ? <div className="block-loading"/>
                                : <img
                                    src={image ? image : defaultImage}
                                    alt="Detail"
                                    className="card-img-top"
                                />}
                        </div>
                        <Label className="form-group has-float-label mt-3">
                            <Input type="text" name="title" value={title}
                                   onChange={event => this.onInputChange(event)}/>
                            <IntlMessages id="forms.point"/>
                        </Label>
                        <InputGroup className="mb-3">
                            <CustomInput
                                type="file"
                                id="exampleCustomFileBrowser2"
                                name="customFile"
                                accept=".jpg, .jpeg, .png"
                                innerRef={this.inputElement}
                                onChange={event => this.onImageUpload(event)}
                            />
                        </InputGroup>
                        <Label className="form-group has-float-label">
                            <Input type="textarea" name="info" value={info}
                                   onChange={event => this.onInputChange(event)}/>
                            <IntlMessages id="treasure.info"/>
                        </Label>
                        <Label className="form-group has-float-label">
                            <Input type="textarea" name="greetingMessage" value={greetingMessage}
                                   onChange={event => this.onInputChange(event)}/>
                            <IntlMessages id="treasure.greeting.message"/>
                        </Label>
                        <Label className="form-group has-float-label">
                            <Input type="textarea" name="goodbyeMessage" value={goodbyeMessage}
                                   onChange={event => this.onInputChange(event)}/>
                            <IntlMessages id="treasure.goodbye.message"/>
                        </Label>
                        <InputGroup className="mb-3">
                            <Input
                                type="number"
                                step="1"
                                name="expirationTime"
                                value={expirationTime}
                                onChange={event => this.onInputChange(event)}
                            />
                            <InputGroupAddon addonType="append">Minutes</InputGroupAddon>
                        </InputGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" outline onClick={this.toggleEditModal}>
                            <IntlMessages id="survey.cancel"/>
                        </Button>
                        <Button color="primary"
                                disabled={treasure.title.length === 0}
                                onClick={!forceClick ? this.toggleEditModal : match && match.params.id ? this.editTreasure : this.createTreasure}>
                            <IntlMessages id="survey.submit"/>
                        </Button>
                    </ModalFooter>
                </Modal>


                <Modal
                    isOpen={previewOpen}
                    toggle={this.previewModal}
                    className="treasure-preview-modal"
                >
                    <div className="preview-modal-header">
                        <div className="preview-modal-button"
                             onClick={this.previewModal}>
                            <i style={{marginRight: '5px'}} className={'simple-icon-arrow-left'}/>{" "}
                            <IntlMessages id="survey.back"/>
                        </div>
                        <PublicTopNav/>
                    </div>
                    <ModalBody style={{padding: 0}}>
                        <this.MapWithAMarker
                            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                            loadingElement={<div className="map-item"/>}
                            containerElement={<div className="map-item"/>}
                            mapElement={<div className="map-item"/>}
                        />
                    </ModalBody>
                </Modal>
                {modalOpen && <AddNewSurveyModal
                    onCreate={this.addNewPoint}
                    onEdit={this.editPoint}
                    onDelete={this.deletePoint}
                    toggleModal={this.toggleModal}
                    modalOpen={modalOpen}
                    editPoint={editPoint}
                />}
                <DeleteTreasureModal
                    toggle={this.toggleDelete}
                    modal={deleteModalOpen}
                    onAccept={this.deleteTreasure}
                    treasure={title}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = ({treasures, authUser}) => {
    const {user} = authUser;
    return {
        treasures,
        user,
    };
};
export default withRouter(connect(
    mapStateToProps,
    {
        addTreasure,
        editTreasure,
        deleteTreasure,
    }
)(TreasureDetails));

