import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import treasures from './treasures/reducer';
import accounts from './accounts/reducer'

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  treasures,
  accounts
});

export default reducers;