import {
  GET_ALL_USERS,
  GET_ALL_USERS_ERROR,
  GET_ALL_USERS_SUCCESS,
    UPDATE_USERS_ROLE
} from '../actions';

export const getAllUsers = () => ({
  type: GET_ALL_USERS
});

export const getAllUsersSuccess = (users) => ({
  type: GET_ALL_USERS_SUCCESS,
  payload: users
});

export const updateUsersRole = (users) => ({
    type: UPDATE_USERS_ROLE,
    payload: users
});

export const getAllUsersError = (error) => ({
  type: GET_ALL_USERS_ERROR,
  payload: error
});
