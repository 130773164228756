import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_ERROR,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    LOGOUT_USER,
    LOGIN_USER_GOOGLE,
    FORGET_PASSWORD,
    GET_ALL_USERS,
    GET_ALL_USERS_ERROR,
    GET_ALL_USERS_SUCCESS,
    GET_USER_CLAIMS,
    GET_USER_CLAIMS_SUCCESS,
    SHOW_USER_ERROR,
    GET_CURRENTLY_USER,
    UPDATE_CURRENT_USER,
    SHOW_USER_MESSAGE
} from '../actions';

const INIT_STATE = {
    user: null,
    loading: false,
    isAdmin: false,
    error: '',
    message: '',
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return {...state, loading: true};
        case  LOGIN_USER_GOOGLE:
            return {...state, loading: true};
        case LOGIN_USER_SUCCESS:
            return {...state, loading: false, user: action.payload};
        case LOGIN_USER_ERROR:
            return {...state, loading: false};
        case REGISTER_USER:
            return {...state, loading: true};
        case REGISTER_USER_SUCCESS:
            return {...state, loading: false, message: "Please verify your email."};
        case FORGET_PASSWORD:
            return {...state};
        case LOGOUT_USER:
            return {...state, user: null, loading: false};
        case GET_ALL_USERS:
            return {...state,};
        case GET_ALL_USERS_SUCCESS:
            const users = [];
            return {...state, users};
        case GET_ALL_USERS_ERROR:
            return {...state, loading: false};
        case GET_CURRENTLY_USER:
            return {...state, loading: true};
        case GET_USER_CLAIMS:
            return {...state};
        case GET_USER_CLAIMS_SUCCESS:
            return {...state, isAdmin: action.payload};
        case SHOW_USER_ERROR:
            return {...state, error: action.payload, loading: false};
        case SHOW_USER_MESSAGE:
            return {...state, message: action.payload, loading: false};
        case UPDATE_CURRENT_USER:
            return {...state, loading: false, user: action.payload};
        default:
            return {...state};
    }
}
