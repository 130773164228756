import React, {Component} from "react";
import {
    Row,
    Button,
} from "reactstrap";
import {injectIntl} from "react-intl";

import {Colxx, Separator} from "../../components/common/CustomBootstrap";
import Breadcrumb from "../navs/Breadcrumb";
import IntlMessages from "../../helpers/IntlMessages";

class ListPageHeading extends Component {

    render() {
        const {
            match,
            toggleModal,
            heading
        } = this.props;

        return (
            <Row>
                <Colxx xxs="12">
                    <div className="mb-2">
                        <h1>
                            <IntlMessages id={heading}/>
                        </h1>

                        <div className="text-zero top-right-button-container">
                            <Button
                                color="primary"
                                size="lg"
                                className="top-right-button"
                                onClick={() => toggleModal()}
                            >
                                <IntlMessages id="pages.add-new"/>
                            </Button>
                        </div>
                        <Breadcrumb match={match}/>
                    </div>
                    <Separator className="mb-5"/>
                </Colxx>
            </Row>
        );
    }
}

export default injectIntl(ListPageHeading);
