import React, { Component } from "react";
import { Row, Card, CardTitle, Form, Label, Input, Button } from "reactstrap";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import {registerUser, setUserError} from "../../redux/actions";

import IntlMessages from "../../helpers/IntlMessages";
import { Colxx } from "../../components/common/CustomBootstrap";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        email: "",
        password: "",
        name: ""
      }
    };
  }

  componentWillUnmount() {
    const {setUserError, error} = this.props;
    error && setUserError('');
  }

  onInputChange = event => {
    const {user} = this.state;
    const {setUserError, error} = this.props;
    error && setUserError('');
    user[event.target.name] = event.target.value;
    this.setState({user});
  };

  onUserRegister = () => {
    const {user} = this.state;
    const {registerUser, history} = this.props;
    if (user.email !== "" && user.password !== ""){
      registerUser(user, history);
    }
  };

  render() {
    const {user} = this.state;
    const {error, loading} = this.props;
    return (
      <Row className="h-100">
        <Colxx xxs="12" md="6" className="mx-auto my-auto">
          <Card className="auth-card">
            {/*<div className="position-relative image-side ">*/}
              {/*<p className="text-white h2">MAGIC IS IN THE DETAILS</p>*/}
              {/*<p className="white mb-0">*/}
                {/*Please use this form to register. <br />*/}
                {/*If you are a member, please{" "}*/}
                {/*<NavLink to={`/login`} className="white">*/}
                  {/*login*/}
                {/*</NavLink>*/}
                {/*.*/}
              {/*</p>*/}
            {/*</div>*/}
            <div className="form-side">
              <NavLink to={`/`} className="white">
                <span className="logo-single" />
              </NavLink>
              <CardTitle className="mb-4">
                <IntlMessages id="user.register" />
              </CardTitle>
              <Form>
                <Label className="form-group has-float-label mb-4">
                  <Input type="name" name="name" defaultValue={user.name} onChange={this.onInputChange}/>
                  <IntlMessages id="user.fullname" />
                </Label>
                <Label className="form-group has-float-label mb-4">
                  <Input type="email" name="email" defaultValue={user.email} onChange={this.onInputChange}/>
                  <IntlMessages id="user.email" />
                </Label>
                <Label className="form-group has-float-label mb-4">
                  <Input type="password" name="password" value={user.password} onChange={this.onInputChange}/>
                  <IntlMessages id="user.password"/>
                </Label>
                <div className="user-login-error w-100 my-3 text-center">
                  {error}
                </div>
                <div className="d-flex justify-content-end align-items-center">
                  <Button
                    color="primary"
                    className="btn-shadow"
                    size="lg"
                    disabled={loading}
                    onClick={() => this.onUserRegister()}
                  >
                    <IntlMessages id="user.register-button" />
                  </Button>
                </div>
              </Form>
            </div>
          </Card>
        </Colxx>
      </Row>
    );
  }
}
const mapStateToProps = ({ authUser }) => {
  const { error, loading } = authUser;
  return { error };
};

export default connect(
  mapStateToProps,
  {
    registerUser,
    setUserError
  }
)(Register);
