import React, {Component, Fragment} from "react";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import {Row} from "reactstrap";

import {Colxx} from "../../../components/common/CustomBootstrap";

import ChatHeading from "../../../components/applications/ChatHeading";
import MessageCard from "../../../components/applications/MessageCard";
import SaySomething from "../../../components/applications/SaySomething";

class ChatApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messageInput: "",
            loadingChat: false,
            loadingConversations: true,
            loadingContacts: true,
            selectedConversation: true,
            timerId: null
        };
    }

    componentWillMount() {
        const {update} = this.props;
        const timerId = setInterval(() =>
            update(), 5000);
        this.setState({timerId})
    }

    componentWillUnmount() {
        clearInterval(this.state.timerId)
    }

    handleChatInputPress = e => {
        if (e.key === "Enter") {
            if (this.state.messageInput.length > 0) {
                this.props.sendMessage(this.state.messageInput);
                this.setState({
                    messageInput: ""
                });
            }
        }
    };

    handleChatInputChange = e => {
        this.setState({
            messageInput: e.target.value
        });
    };

    handleSendButtonClick = () => {
        if (this.state.messageInput.length > 0) {
            this.setState({
                messageInput: "",
                menuActiveTab: "messages"
            });
        }
    };

    toggleAppMenu = tab => {
        this.setState({
            menuActiveTab: tab
        });
    };


    render() {
        const {loadingConversations, loadingContacts, selectedUser, selectedConversation, allContacts, messages} = this.state;
        const {messageInput} = this.state;
        const {user, currentUser} = this.props;
        return loadingConversations && loadingContacts ? (
            <Fragment>
                <Row>
                    <Colxx xxs="12" className="chat-app">
                        {user && (
                            <ChatHeading
                                name={`${user.first_name} ${user.last_name}`}
                                thumb={user.picture}
                                lastSeenDate={'22:17'}
                            />
                        )}

                        {selectedConversation && (
                            <PerfectScrollbar
                                option={{suppressScrollX: true, wheelPropagation: false}}
                            >
                                {user.messages.map((item, index) => {
                                    return (
                                        <MessageCard
                                            key={index}
                                            sender={user}
                                            item={item}
                                            currentUser={currentUser}
                                        />
                                    );
                                })}
                            </PerfectScrollbar>
                        )}
                    </Colxx>
                </Row>
                <SaySomething
                    placeholder={"Say something"}
                    messageInput={messageInput}
                    handleChatInputPress={this.handleChatInputPress}
                    handleChatInputChange={this.handleChatInputChange}
                    handleSendButtonClick={this.handleSendButtonClick}
                />
            </Fragment>
        ) : (
            <div className="loading"/>
        );
    }
}

const mapStateToProps = ({authUser}) => {
    const {user} = authUser;
    return {currentUser: user}
};

export default injectIntl(
    connect(
        mapStateToProps,
        {}
    )(ChatApp)
);
