import React from "react";

const PublicTopNav = () => {
    return (
        <nav className="navbar position-relative">
            <a className="navbar-logo" href="/">
                <span className="logo d-none d-xs-block"/>
                <span className="logo-mobile d-block d-xs-none"/>
            </a>
        </nav>
    );
};

export default PublicTopNav;

