import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import TreasureListPages from './treasures';
import TreasureDetails from './treasure-detail'

const Treasures = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/all`} />
            <Route path={`${match.url}/all`} component={TreasureListPages} />
            <Route path={`${match.url}/create`} component={TreasureDetails} />
            <Route path={`${match.url}/:id`} component={TreasureDetails} />
            <Redirect to="/error" />
        </Switch>
    </div>
);
export default Treasures;