import React from "react";
import {Card} from "reactstrap";
import {NavLink} from "react-router-dom";
import {Colxx} from "../../components/common/CustomBootstrap";

const DataListView = ({treasure}) => {
    return (
        <Colxx xxs="12" className="mb-3">

            <Card>
                <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                    <div
                        className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-start min-width-zero align-items-lg-center">
                        <NavLink to={`${treasure.uid}`} className="w-40 w-sm-100">
                            <p className="list-item-heading mb-1 truncate">
                                {treasure.title}
                            </p>
                        </NavLink>
                        <p className="mb-1 text-muted text-small w-15 w-sm-100">
                            {treasure.info}
                        </p>
                    </div>
                </div>
            </Card>
        </Colxx>
    );
};

export default React.memo(DataListView);
