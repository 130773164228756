import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import React from "react";

const DeleteTreasureModal = ({ modal, toggle, onAccept, treasure}) => {
    return (
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                <IntlMessages id="treasures.delete" />
            </ModalHeader>
            <ModalBody>
                Do you really want delete {treasure} treasure?
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={onAccept}>
                    Delete
                </Button>{" "}
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};
export default DeleteTreasureModal;