import React, {Component} from "react";
import {
    Row,
    Button,
    Collapse
} from "reactstrap";
import {injectIntl} from "react-intl";

import {Colxx, Separator} from "../../components/common/CustomBootstrap";
import Breadcrumb from "../navs/Breadcrumb";
import IntlMessages from "../../helpers/IntlMessages";

import {
    DataListIcon,
    ThumbListIcon,
    ImageListIcon
} from "../../components/svg";

class ListPageHeading extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayOptionsIsOpen: false
        };
    }

    toggleDisplayOptions = () => {
        this.setState(prevState => ({
            displayOptionsIsOpen: !prevState.displayOptionsIsOpen
        }));
    };


    render() {
        const {
            displayMode,
            changeDisplayMode,
            match,
            toggleModal,
            heading
        } = this.props;

        return (
            <Row>
                <Colxx xxs="12">
                    <div className="mb-2">
                        <h1>
                            <IntlMessages id={heading}/>
                        </h1>

                        <div className="text-zero top-right-button-container">
                            <Button
                                color="primary"
                                size="lg"
                                className="top-right-button"
                                onClick={() => toggleModal()}
                            >
                                <IntlMessages id="pages.add-new"/>
                            </Button>
                        </div>
                        <Breadcrumb match={match}/>
                    </div>

                    <div className="mb-3">
                        <Button
                            color="empty"
                            className="pt-0 pl-0 d-inline-block d-md-none"
                            onClick={this.toggleDisplayOptions}
                        >
                            <IntlMessages id="pages.display-options"/>{" "}
                            <i className="simple-icon-arrow-down align-middle"/>
                        </Button>
                        <Collapse
                            className="d-md-block"
                            id="displayOptions"
                        >
              <span className="mr-3 mb-2 d-inline-block float-md-left">
                <a
                    href="#/"
                    className={`mr-2 view-icon ${
                        displayMode === "list" ? "active" : ""
                        }`}
                    onClick={() => changeDisplayMode("list")}
                >
                  <DataListIcon/>
                </a>
                <a
                    href="#/"
                    className={`mr-2 view-icon ${
                        displayMode === "thumblist" ? "active" : ""
                        }`}
                    onClick={() => changeDisplayMode("thumblist")}
                >
                  <ThumbListIcon/>
                </a>
                <a
                    href="#/"
                    className={`mr-2 view-icon ${
                        displayMode === "imagelist" ? "active" : ""
                        }`}
                    onClick={() => changeDisplayMode("imagelist")}
                >
                  <ImageListIcon/>
                </a>
              </span>
                            <div className="d-block d-md-inline-block">
                            </div>
                        </Collapse>
                    </div>
                    <Separator className="mb-5"/>
                </Colxx>
            </Row>
        );
    }
}

export default injectIntl(ListPageHeading);
