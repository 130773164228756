import {all} from 'redux-saga/effects';
import authSagas from './auth/saga';
import treasuresSagas from "./treasures/saga";
import accountsSagas from "./accounts/saga"


export default function* rootSaga(getState) {
    yield all([
        authSagas(),
        treasuresSagas(),
        accountsSagas()
    ]);
}
