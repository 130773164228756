import React, {Component, Fragment} from "react";
import {Row} from "reactstrap";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import {withRouter} from 'react-router-dom';
import DataListView from "../../../containers/treasures/DataListView";
import ListPageHeading from "../../../containers/treasures/ListPageHeading";
import ImageListView from "../../../containers/treasures/ImageListView";
import ThumbListView from "../../../containers/treasures/ThumbListView";
import {getAllTreasures} from "../../../redux/treasures/actions"

class TreasureListPages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayMode: "imagelist",
        };
    }

    componentDidMount() {
        const {getAllTreasures, isAdmin, user} = this.props;
        getAllTreasures(isAdmin, user.uid);
    }

    changeDisplayMode = mode => {
        this.setState({
            displayMode: mode
        });
        return false;
    };

    render() {
        const {
            displayMode,
        } = this.state;

        const {match, loading} = this.props;
        const {treasures} = this.props.treasures;
        return (
            <Fragment>
                <div className="disable-text-selection">
                    <ListPageHeading
                        heading="menu.treasure-list"
                        displayMode={displayMode}
                        changeDisplayMode={this.changeDisplayMode}
                        match={match}
                        toggleModal={() => this.props.history.push(`/app/treasures/create`)}
                    />
                    {loading
                        ? <div className="loading"/>
                        : <Row>
                            {treasures && treasures.map(treasure => {
                                if (displayMode === "imagelist") {
                                    return (
                                        <ImageListView
                                            key={treasure.uid}
                                            treasure={treasure}
                                        />
                                    );
                                } else if (displayMode === "thumblist") {
                                    return (
                                        <ThumbListView
                                            key={treasure.uid}
                                            treasure={treasure}
                                        />
                                    );
                                } else {
                                    return (
                                        <DataListView
                                            key={treasure.uid}
                                            treasure={treasure}
                                        />
                                    );
                                }
                            })}
                        </Row>}
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = ({treasures, authUser}) => {
    const {isAdmin, user} = authUser;
    const {loading} = treasures;
    return {
        treasures, loading, isAdmin, user
    };
};

export default withRouter(
    injectIntl(
        connect(
            mapStateToProps,
            {
                getAllTreasures
            }
        )(TreasureListPages)
    ))
;