import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter} from "react-router-dom";

import PublicTopNav from "../containers/navs/PublicTopnav";

class PublicLayout extends Component {
    render() {
        const { containerClassnames } = this.props;
        return (
            <div id="public-container" className={containerClassnames}>
                <PublicTopNav history={this.props.history} />
                <main>
                    <div className="container-fluid">
                        {this.props.children}
                    </div>
                </main>
            </div>
        );
    }
}
const mapStateToProps = ({ menu }) => {
    const { containerClassnames } = menu;
    return { containerClassnames };
};
const mapActionToProps={}

export default withRouter(connect(
    mapStateToProps,
    mapActionToProps
)(PublicLayout));
