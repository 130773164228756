import React, {Component} from "react";
import {Row, Card, CardTitle, Form, Label, Input, Button} from "reactstrap";
import {NavLink} from "react-router-dom";
import {Colxx} from "../../components/common/CustomBootstrap";
import IntlMessages from "../../helpers/IntlMessages";
import {connect} from "react-redux";
import {forgotPassword, setUserError} from "../../redux/auth/actions";
import {confirmPasswordReset} from "../../redux/auth/saga";

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dto: {
                email: "",
                password: ""
            },
            code: null,
        };
    }

    componentDidMount() {
        const {history} = this.props;
        if (history.location && history.location.code && history.location.email) {
            const {dto} = this.state;
            dto.email = history.location.email;
            this.setState({code: history.location.code, dto})
        }
    }

    onInputChange = event => {
        const {dto} = this.state;
        dto[event.target.name] = event.target.value;
        this.setState({dto});
    };

    onForgotPassword = () => {
        const {code} = this.state;
        const {email, password} = this.state.dto;
        const {forgotPassword, setUserError, history} = this.props;
        code ? confirmPasswordReset(code, password).then(result => {
                history.push('/');
            }
        ).catch(error => setUserError(error.message)) : forgotPassword(email, history);
    };

    componentWillUnmount() {
        const {setUserError, error} = this.props;
        error && setUserError('');
    }

    render() {
        const {code} = this.state;
        const {email, password} = this.state.dto;
        const {error} = this.props;
        return (
            <Row className="h-100">
                <Colxx xxs="12" md="6" className="mx-auto my-auto">
                    <Card className="auth-card">
                        {/*<div className="position-relative image-side ">*/}
                        {/*  <p className="text-white h2">MAGIC IS IN THE DETAILS</p>*/}
                        {/*  <p className="white mb-0">*/}
                        {/*    Please use your e-mail to reset your password. <br />*/}
                        {/*    If you are not a member, please{" "}*/}
                        {/*    <NavLink to={`/register`} className="white">*/}
                        {/*      register*/}
                        {/*    </NavLink>*/}
                        {/*    .*/}
                        {/*  </p>*/}
                        {/*</div>*/}
                        <div className="form-side">
                            <NavLink to={`/`} className="white">
                                <span className="logo-single"/>
                            </NavLink>
                            <CardTitle className="mb-4">
                                <IntlMessages id="user.forgot-password"/>
                            </CardTitle>
                            <Form>
                                <Label className="form-group has-float-label mb-4">
                                    <Input type="email" name="email" disabled={!!code} value={email}
                                           onChange={event => this.onInputChange(event)}/>
                                    <IntlMessages id="user.email"/>
                                </Label>
                                {code && <Label className="form-group has-float-label mb-4">
                                    <Input type="text" name="password" value={password}
                                           onChange={event => this.onInputChange(event)}/>
                                    <IntlMessages id="account.password.new"/>
                                </Label>}
                                <div className="user-login-error w-100 my-3 text-center">
                                    {error}
                                </div>
                                <div className="d-flex justify-content-end align-items-center">
                                    <Button
                                        color="primary"
                                        className="btn-shadow"
                                        size="lg"
                                        onClick={() => this.onForgotPassword()}
                                    >
                                        <IntlMessages id="user.reset-password-button"/>
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </Card>
                </Colxx>
            </Row>
        );
    }
}

const mapStateToProps = ({authUser}) => {
    const {user, loading, error} = authUser;
    return {user, loading, error};
};

export default connect(
    mapStateToProps,
    {
        forgotPassword,
        setUserError
    }
)(ForgotPassword);
