import {
    GET_ALL_USERS,
    GET_ALL_USERS_ERROR,
    GET_ALL_USERS_SUCCESS,
    UPDATE_USERS_ROLE

} from '../actions';

const INIT_STATE = {
    loading: false,
    users: []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_USERS:
            return {...state, loading: true};
        case GET_ALL_USERS_SUCCESS:
            const users = [...action.payload];
            return {...state, loading: false, users: users};
        case GET_ALL_USERS_ERROR:
            return {...state, loading: false};
        case UPDATE_USERS_ROLE:
            const updatedUsers = [...action.payload];
            return {...state, loading: false, users: updatedUsers};

        default:
            return {...state};
    }
}
