import React from 'react'
import {QRCode} from 'react-qrcode-logo'
import {Card, CardBody} from "reactstrap";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {NotificationManager} from '../../common/react-notifications';
import {TELEGRAM_BOT_NAME} from '../../../config';
import "./qr-tresures.css"


export default class QRTreasures extends React.Component {
    state = {
        copied: false
    };

    copy = () => {
        this.setState({copied: true}, () => NotificationManager.primary(
            "Сode has been copied",
            "Copy code",
            3000,
            null,
            null
            )
        )
    };

    downloadCanvas() {
        const canvas = document.getElementById("react-qrcode-logo");
        const downloadButton = document.getElementById("downloadButton");
        const image = canvas.toDataURL("image/jpg");
        downloadButton.href = image;
    };

    render() {
        const {treasureId} = this.props;
        const {copied} = this.state;


        return (
            <Card className="mb-4">
                <CardBody>
                    <h6 className="qr-header">
                        TR_{treasureId}
                        <CopyToClipboard
                            text={`TR_${treasureId}`}
                            onCopy={() => this.copy()}>
                            <span style={{
                                color: copied ? '#145388' : 'green',
                                cursor: 'pointer'
                            }}> <i className="simple-icon-docs"/></span>
                        </CopyToClipboard>
                    </h6>
                    <div className="qr-wrapper">
                        <QRCode
                            value={`https://t.me/${TELEGRAM_BOT_NAME}?start=${treasureId}`}
                            logoImage="/assets/img/logo-mobile.svg"
                            size={300}
                            logoWidth={200}
                        />
                        <a id="downloadButton" download="myImage.jpg" href="" onClick={this.downloadCanvas}/>
                    </div>
                    <div className="qr-text">
                        <b>Note:</b> Please, scan a qr-code or
                        <CopyToClipboard
                            text={`TR_${treasureId}`}
                            onCopy={() => this.copy()}>
                            <span style={{
                                color: copied ? '#145388' : 'green',
                                cursor: 'pointer'
                            }}> <b>copy</b> </span>
                        </CopyToClipboard>
                        a code and send it to the bot!
                    </div>
                </CardBody>
            </Card>
        )
    }
}
