const data = [
    {
        id: "treasures",
        icon: "iconsminds-location-2",
        label: "menu.treasures",
        to: "/app/treasures",
        access: "all",
        subs: []
    },
    {
        id: "accounts",
        icon: "iconsminds-user",
        label: "menu.accounts",
        to: "/app/accounts",
        access: "admin",
        subs: []
    },
];
export default data;
