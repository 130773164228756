import React from "react";
import {NavLink, withRouter} from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import {Card, CardBody, CardHeader, Nav, NavItem, TabContent, TabPane} from "reactstrap";

import IntlMessages from "../../helpers/IntlMessages";
import {compose} from "redux";

const Tickets = ({points, onMarkerClick, activeTab, users, treasureLength, onUserClick, setActiveTab}) => {
    return (
        <Card className="mb-4">
            <CardHeader className="pl-0 pr-0">
                <Nav tabs className="card-header-tabs ml-0 mr-0">
                    <NavItem className="w-50 text-center">
                        <NavLink
                            className={`${activeTab === 'points' && 'active'} nav-link`}
                            onClick={() => {
                                setActiveTab('points');
                            }}
                            to="#"
                        >
                            <IntlMessages id="treasures.points"/>
                        </NavLink>
                    </NavItem>
                    <NavItem className="w-50 text-center">
                        <NavLink
                            className={`${activeTab === 'users' && 'active'} nav-link`}
                            onClick={() => {
                                setActiveTab('users');
                            }}
                            to="#"
                        >
                            <IntlMessages id="dashboards.tickets"/>
                        </NavLink>
                    </NavItem>
                </Nav>
            </CardHeader>
            <CardBody>
                <TabContent
                    activeTab={activeTab}
                    className="chat-app-tab-content"
                >
                    <TabPane tabId="points" className="chat-app-tab-pane">
                        <div className="dashboard-list-with-user">
                            <PerfectScrollbar
                                option={{suppressScrollX: true, wheelPropagation: false}}
                            >
                                {points && points.length > 0 ? points.map((point, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="d-flex flex-row mb-3 pb-3 border-bottom"
                                        >
                                            <div className="point-hover-effect" onClick={() => onMarkerClick(point)}>
                                                <i style={{fontSize: "32px"}} className="simple-icon-location-pin"/>
                                            </div>

                                            <div className="pl-3 pr-2">
                                                <div className="point-hover-effect"
                                                     onClick={() => onMarkerClick(point)}>
                                                    <p className="font-weight-medium mb-0 ">{point.name}</p>
                                                    <p className="text-muted mb-0 text-small">
                                                        {point.intro}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }) : <IntlMessages id="point.missing"/>}
                            </PerfectScrollbar>
                        </div>
                    </TabPane>
                    <TabPane tabId="users" className="chat-app-tab-pane">
                        <div className="dashboard-list-with-user">
                            <PerfectScrollbar
                                option={{suppressScrollX: true, wheelPropagation: false}}
                            >
                                {users && users.length !== 0 ? users.map((user, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="d-flex flex-row mb-3 pb-3 border-bottom point-hover-effect"
                                            onClick={() => onUserClick(user)}
                                        >
                                            <img
                                                src={user.picture}
                                                alt={user.first_name}
                                                className="img-thumbnail border-0 rounded-circle list-thumbnail align-self-center xsmall"
                                            />
                                            <div className="pl-3 pr-2">
                                                <p className="font-weight-medium mb-0 ">{`${user.first_name} ${user.last_name}`}</p>
                                                <p className="text-muted mb-0 text-small d-flex align-items-center">
                                                <span className="mr-1 d-flex align-items-center">
                                                  <i className={user.gender === 'male' ? 'simple-icon-user' : 'simple-icon-user-female'}/>
                                                </span>
                                                    <span className="d-flex align-items-center">
                                                  <i className="simple-icon-location-pin mr-1"/>{user.locale}
                                                </span>
                                                    <span className="mr-1 d-flex align-items-center">
                                                  <i className="iconsminds-eifel-tower"/>{user.points.length}/{treasureLength}
                                                </span>
                                                    <span className="mr-1 d-flex align-items-center">
                                                  <i className="simple-icon-badge mr-1"/>{user.totalPoints}
                                                </span>
                                                </p>
                                            </div>
                                        </div>
                                    );
                                }) : <IntlMessages id="user.missing"/>}
                            </PerfectScrollbar>
                        </div>
                    </TabPane>
                </TabContent>
            </CardBody>
        </Card>
    );
};

export default compose(withRouter)(Tickets);
