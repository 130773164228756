import React, {Component} from "react";
import {Row, Card, CardTitle, Form, Label, Input, Button} from "reactstrap";
import {NavLink} from "react-router-dom";
import {connect} from "react-redux";
import GoogleButton from 'react-google-button'

import {loginUser, loginUserGoogle, setUserError} from "../../redux/actions";
import {Colxx} from "../../components/common/CustomBootstrap";
import IntlMessages from "../../helpers/IntlMessages";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                email: "",
                password: ""
            }
        };
    }

    componentWillUnmount() {
        const {setUserError, error} = this.props;
        error && setUserError('');
    }

    onUserLogin = () => {
        const {user} = this.state;
        const {loginUser, history} = this.props;
        if (user.email !== "" && user.password !== "") {
            loginUser(user, history);
        }
    };

    onGoogleLogin = () => {
        const {loginUserGoogle, history} = this.props;
        loginUserGoogle(history);
    };

    onInputChange = event => {
        const {user} = this.state;
        const {setUserError, error} = this.props;
        error && setUserError('');
        user[event.target.name] = event.target.value;
        this.setState({user});
    };

    render() {
        const {user} = this.state;
        const {error, loading, message} = this.props;
        return (
            <Row className="h-100">
                <Colxx xxs="12" md="6" className="mx-auto my-auto">
                    <Card className="auth-card">
                        {/*<div className="position-relative image-side ">*/}
                        {/*<p className="text-white h2">MAGIC IS IN THE DETAILS</p>*/}
                        {/*<p className="white mb-0">*/}
                        {/*Please use your credentials to login.*/}
                        {/*<br />*/}
                        {/*If you are not a member, please{" "}*/}
                        {/*<NavLink to={`/user/register`} className="white">*/}
                        {/*register*/}
                        {/*</NavLink>*/}
                        {/*.*/}
                        {/*</p>*/}
                        {/*</div>*/}
                        <div className="form-side">
                            <NavLink to={`/`} className="white">
                                <span className="logo-single"/>
                            </NavLink>
                            <CardTitle className="mb-4">
                                <IntlMessages id="user.login-title"/>
                            </CardTitle>
                            <Form>
                                <Label className="form-group has-float-label mb-4">
                                    <Input type="email" name="email" value={user.email} onChange={this.onInputChange}/>
                                    <IntlMessages id="user.email"/>
                                </Label>
                                <Label className="form-group has-float-label mb-4">
                                    <Input type="password" name="password" value={user.password}
                                           onChange={this.onInputChange}/>
                                    <IntlMessages
                                        id="user.password"
                                        defaultValue={this.state.password}
                                    />
                                </Label>
                                <div className="user-login-error w-100 my-3 text-center">
                                    {error}
                                </div>
                                <div className="w-100 my-3 text-center">
                                    {message}
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <NavLink to={`/user/forgot-password`}>
                                        <IntlMessages id="user.forgot-password-question"/>
                                    </NavLink>
                                    <Button
                                        color="primary"
                                        className="btn-shadow"
                                        size="lg"
                                        disabled={loading}
                                        onClick={() => this.onUserLogin()}
                                    >
                                        <IntlMessages id="user.login-button"/>
                                    </Button>
                                </div>
                                <div
                                    className="user-login-or-wrapper d-flex w-100 justify-content-between align-items-center my-3">
                                    <div className="user-login-line flex-grow-1"/>
                                    <div className="mx-3 flex-grow-0">OR</div>
                                    <div className="user-login-line flex-grow-1"/>
                                </div>
                                <div className="d-flex align-items-center justify-content-center">
                                    <GoogleButton
                                        type="dark"
                                        onClick={() => {
                                            this.onGoogleLogin()
                                        }}
                                    />
                                </div>
                                <div className="user-login-sign-up d-flex w-100 justify-content-center mt-3">
                                    <NavLink to={`/user/register`}>
                                        <IntlMessages id="user.sign-up"/>
                                    </NavLink>
                                </div>
                            </Form>
                        </div>
                    </Card>
                </Colxx>
            </Row>
        );
    }
}

const mapStateToProps = ({authUser}) => {
    const {error, loading, message} = authUser;
    return {error, loading, message};
};

export default connect(
    mapStateToProps,
    {
        loginUser,
        loginUserGoogle,
        setUserError
    }
)(Login);
