import React, {Component} from "react";
import {connect} from "react-redux";
import {
    CustomInput,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label, CardBody
} from "reactstrap";

import {Formik, Form, Field} from "formik";

import IntlMessages from "../../helpers/IntlMessages";
import {randomKey} from "../../helpers/Utils";


class AddNewSurveyModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            point: {
                id: randomKey(10),
                lat: null,
                lng: null,
                name: "",
                intro: "",
                additionalChallenge: "",
                additionalChallengePoints: 0,
                questionPoints: 10,
                question: {
                    title: "",
                    answers: [
                        {
                            isCorrect: true,
                            title: "",
                        },
                        {
                            isCorrect: false,
                            title: "",
                        }
                    ]
                }
            }
        };
    }

    componentDidMount() {
        const {editPoint} = this.props;
        editPoint && this.setState({point: editPoint});
    };

    addNewItem = () => {
        const {point} = this.state;
        const {onCreate} = this.props;
        onCreate(point);
    };

    editItem = () => {
        const {point} = this.state;
        const {onEdit} = this.props;
        onEdit(point);
    };

    deleteItem = () => {
        const {point} = this.state;
        const {onDelete} = this.props;
        onDelete(point);
    };

    onInputChange = event => {
        const {point} = this.state;
        point[event.target.name] = event.target.value;
        this.setState({point});
    };

    onQuestionChange = event => {
        const {point} = this.state;
        const {question} = point;
        question[event.target.name] = event.target.value;
        this.setState({point});
    };

    onAnswerChange = (event, index) => {
        const {point} = this.state;
        const {answers} = point.question;
        answers[index][event.target.name] = event.target.value;
        this.setState({point});
    };

    addAnswer = () => {
        const {point} = this.state;
        const {question} = point;
        question.answers.length < 8 && question.answers.push(
            {
                isCorrect: false,
                title: "",
            }
        );
        this.setState({point})
    };

    onAnswerDelete = (index) => {
        const {point} = this.state;
        const {question} = point;
        question.answers.splice(index, 1);
        this.setState({point})
    };


    render() {
        const {modalOpen, toggleModal, onAccept} = this.props;
        const {intro, name, additionalChallenge, additionalChallengePoints, questionPoints, question} = this.state.point;
        return (
            <Modal
                isOpen={modalOpen}
                toggle={toggleModal}
                wrapClassName="modal-right"
                backdrop="static"
            >
                <ModalHeader toggle={toggleModal}>
                    <IntlMessages id={this.props.editPoint ? "survey.edit-new-title" : "survey.add-new-title"}/>
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <Label className="form-group has-float-label">
                            <Input type="text" name="name" value={name}
                                   onChange={event => this.onInputChange(event)}/>
                            <IntlMessages id="point.name"/>
                        </Label>
                        <Label className="form-group has-float-label  mb-5">
                            <Input type="textarea" name="intro" value={intro}
                                   onChange={event => this.onInputChange(event)}/>
                            <IntlMessages id="point.intro"/>
                        </Label>

                        <Label className="form-group has-float-label mt-3">
                            <Input type="text" name="title" value={question.title}
                                   onChange={event => this.onQuestionChange(event)}/>
                            <IntlMessages id="point.question.title"/>
                        </Label>

                        {question && question.answers && question.answers.map((answer, index) => (
                            <Label className="form-group has-float-label d-flex">
                                <Input className="pr-5" type="text" name="title" value={answer.title}
                                       onChange={event => this.onAnswerChange(event, index)}/>
                                {!answer.isCorrect && <div className="delete-answer-button" onClick={() => this.onAnswerDelete(index) }><i className="simple-icon-trash"/></div>}
                                <IntlMessages id={answer.isCorrect ? `point.question.correct` : `point.question.wrong`}
                                />
                            </Label>
                        ))}

                        {question.answers.length < 8 && <div className="d-flex">
                            <Button
                                outline
                                color="primary"
                                size="sm"
                                className="mb-4 mx-auto"
                                onClick={() => this.addAnswer()}
                            >
                                <i className="simple-icon-plus btn-group-icon"/>{" "}
                                Add Answer
                            </Button>
                        </div>}

                        <Label className="form-group has-float-label">
                            <Input type="textarea" name="additionalChallenge" value={additionalChallenge}
                                   onChange={event => this.onInputChange(event)}/>
                            <IntlMessages id="point.additional"/>
                        </Label>

                        <div className="d-flex w-100">
                            <Label className="form-group has-float-label">
                                <Input type="number" name="additionalChallengePoints" value={additionalChallengePoints}
                                       onChange={event => event.target.value >= 0 && this.onInputChange(event)}/>
                                <IntlMessages id="point.additional.point"/>
                            </Label>
                            <Label className="form-group has-float-label ml-2">
                                <Input type="number" name="questionPoints" value={questionPoints}
                                       onChange={event => event.target.value >= 0 && this.onInputChange(event)}/>
                                <IntlMessages id="point.question.point"/>
                            </Label>
                        </div>

                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" outline onClick={toggleModal}>
                        <IntlMessages id="survey.cancel"/>
                    </Button>
                    <Button color="primary" onClick={() => this.props.editPoint ? this.editItem() : this.addNewItem()}>
                        <IntlMessages id="survey.submit"/>
                    </Button>
                    {this.props.editPoint && <Button color="danger" onClick={() => this.deleteItem()}>
                        <IntlMessages id="survey.delete"/>
                    </Button>}
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = ({surveyListApp}) => {
    return {
        surveyListApp
    };
};
export default connect(
    mapStateToProps,
    {}
)(AddNewSurveyModal);
