import React from "react";
import {
    Row,
    Card,
    CardBody,
    CardSubtitle,
    CardImg,
    CardText,
} from "reactstrap";
import {NavLink} from "react-router-dom";
import {Colxx} from "../../components/common/CustomBootstrap";
import {defaultImage} from "../../constants/defaultValues"

const ImageListView = ({treasure}) => {
    return (
        <Colxx sm="6" lg="4" xl="3" className="mb-3" key={treasure.uid}>
            <Card
            >
                <div className="position-relative">
                    <NavLink to={`${treasure.uid}`} className="w-40 w-sm-100">
                        <CardImg top alt={treasure.title} src={treasure.image ? treasure.image : defaultImage}/>
                    </NavLink>
                </div>
                <CardBody>
                    <Row>
                        <Colxx xxs="12" className="mb-3">
                            <CardSubtitle>{treasure.title}</CardSubtitle>
                            <CardText className="text-muted text-small mb-0 font-weight-light">
                                {treasure.info}
                            </CardText>
                        </Colxx>
                    </Row>
                </CardBody>
            </Card>
        </Colxx>
    );
};

export default React.memo(ImageListView);
