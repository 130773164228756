import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import {Provider} from "react-redux";
import {
    BrowserRouter as Router,
} from "react-router-dom";
import {configureStore} from "./redux/store";

import App from "./App";

ReactDOM.render(
    <Provider store={configureStore()}>
        <Router>
            <App/>
        </Router>
    </Provider>,
    document.getElementById("root")
);
/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
