import {
    TREASURES_GET_LIST,
    TREASURES_GET_LIST_SUCCESS,
    TREASURES_GET_LIST_ERROR,
    TREASURES_ADD_ITEM,
    TREASURES_ADD_ITEM_SUCCESS,
    TREASURES_ADD_ITEM_ERROR,
    TREASURES_EDIT_ITEM,
    TREASURES_EDIT_ITEM_SUCCESS,
    TREASURES_EDIT_ITEM_ERROR,
    TREASURES_DELETE_ITEM,
    TREASURES_DELETE_ITEM_SUCCESS,
    TREASURES_DELETE_ITEM_ERROR,
} from '../actions';

const INIT_STATE = {
    treasures: null,
    image: null,
    loading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case TREASURES_GET_LIST:
            return {...state, loading: true};

        case TREASURES_GET_LIST_SUCCESS:
            const treasures = [];
            action.payload.forEach(doc => {
                treasures.push(doc.data())
            });
            return {...state, loading: false, treasures};

        case TREASURES_GET_LIST_ERROR:
            return {...state, loading: false, error: action.payload};

        case TREASURES_ADD_ITEM:
            return {...state, loading: false, treasure: action.payload};

        case TREASURES_ADD_ITEM_SUCCESS:
            return {...state, loading: true, treasure: action.payload};

        case TREASURES_ADD_ITEM_ERROR:
            return {...state, loading: true, error: action.payload};

        case TREASURES_EDIT_ITEM:
            return {...state, loading: false, treasure: action.payload};

        case TREASURES_EDIT_ITEM_SUCCESS:
            return {...state, loading: true, treasure: action.payload};

        case TREASURES_EDIT_ITEM_ERROR:
            return {...state, loading: true, error: action.payload};

        case TREASURES_DELETE_ITEM:
            return {...state, loading: false};

        case TREASURES_DELETE_ITEM_SUCCESS:
            return {...state, loading: true, treasure: action.payload};

        case TREASURES_DELETE_ITEM_ERROR:
            return {...state, loading: true, error: action.payload};


        default:
            return {...state};
    }
}
