import React, {Component} from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    CustomInput
} from "reactstrap";
import "rc-switch/assets/index.css";
import Switch from "rc-switch";
import IntlMessages from "../../helpers/IntlMessages";
import DeleteAccountModal from "../../components/accounts/DeleteAccountModal/DeleteAccountModal";

class AddNewModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                name: "",
                email: "",
                password: "",
                admin: false,
            },
            uid: null,
            modal: false,

        }
    }

    componentDidMount() {
        const {user} = this.props;
        user && this.setState({
            user: {
                name: user.name,
                email: user.email,
                admin: user.claime && user.claime.admin
            },
            uid: user.uid,
            loading: false,
        })
    }

    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    };

    onInputChange = event => {
        const {user} = this.state;
        user[event.target.name] = event.target.value;
        this.setState({user});
    };

    onSubmitClick = () => {
        this.setState({loading: true});
        const {createUser, editUser} = this.props;
        const {user, uid} = this.state;
        this.props.user ? editUser(user, uid) : createUser(user);
    };

    onDeleteClick = () => {
        this.setState({loading: true});
        const {deleteUser} = this.props;
        const {uid} = this.state;
        deleteUser(uid);
    };

    render() {
        const {modalOpen, toggleModal, user} = this.props;
        const {name, email, password, admin} = this.state.user;
        const {modal, loading} = this.state;

        return (
            <Modal
                isOpen={modalOpen}
                toggle={toggleModal}
                wrapClassName="modal-right"
                backdrop="static"
            >
                <ModalHeader toggle={toggleModal}>
                    {user ? "Edit User" : "Create User"}
                </ModalHeader>
                {loading
                    ? <ModalBody>
                        <div className="loading"/>
                    </ModalBody>
                    : <ModalBody>
                        <Label className="form-group has-float-label">
                            <Input type="text" name="name" value={name}
                                   onChange={event => this.onInputChange(event)}/>
                            <IntlMessages id="account.name"/>
                        </Label>
                        <Label className="form-group has-float-label">
                            <Input type="email" name="email" value={email}
                                   onChange={event => this.onInputChange(event)}/>
                            <IntlMessages id="account.email"/>
                        </Label>
                        <Label className="form-group has-float-label">
                            <Input type="text" name="password" value={password}
                                   onChange={event => this.onInputChange(event)}/>
                            <IntlMessages id={user ? "account.password.new" : "account.password"}/>
                        </Label>
                        <label>
                            <IntlMessages id="account.admin"/>
                            <Switch
                                className="custom-switch custom-switch-primary mb-1"
                                checked={admin}
                                onChange={switchCheckedPrimary => {
                                    const {user} = this.state;
                                    user.admin = switchCheckedPrimary;
                                    this.setState({user});
                                }}
                            />
                        </label>
                    </ModalBody>}
                <ModalFooter>
                    <Button color="secondary" outline onClick={toggleModal} disabled={loading}>
                        <IntlMessages id="pages.cancel"/>
                    </Button>
                    <Button color="primary" onClick={() => this.onSubmitClick()} disabled={loading}>
                        <IntlMessages id="pages.submit"/>
                    </Button>
                    {user && <Button color="danger" onClick={() => this.toggle()} disabled={loading}>
                        <IntlMessages id="pages.delete"/>
                    </Button>}
                </ModalFooter>
                <DeleteAccountModal toggle={this.toggle} modal={modal} onAccept={this.onDeleteClick} account={name}/>
            </Modal>
        );
    }

};

export default AddNewModal;
