import React, {Component} from "react";
import {Route, withRouter, Switch, Redirect} from "react-router-dom";
import {connect} from "react-redux";

import AppLayout from "../../layout/AppLayout";
import treasures from "./treasures";
import accounts from "./accounts";
import {getUserClaims} from "../../redux/auth/actions"

const AdminRoute = ({component: Component, isAdmin, ...rest}) => (
    <Route
        {...rest}
        render={props =>
            isAdmin && <Component {...props} />
        }
    />
);

class App extends Component {
    render() {
        const {match, isAdmin} = this.props;
        return (
            <AppLayout>
                <Switch>
                    <Redirect exact from={`${match.url}/`} to={`${match.url}/treasures`}/>
                    <Route path={`${match.url}/treasures`} component={treasures}/>
                    <AdminRoute path={`${match.url}/accounts`} isAdmin={isAdmin} component={accounts}/>
                    <Redirect to="/error"/>
                </Switch>
            </AppLayout>
        );
    }
}

const mapStateToProps = ({menu, authUser}) => {
    const {containerClassnames} = menu;
    const {isAdmin} = authUser;
    return {containerClassnames, isAdmin};
};

export default withRouter(
    connect(
        mapStateToProps,
        {
            getUserClaims
        }
    )(App)
);
