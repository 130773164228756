import React from "react";
import {Card, Badge} from "reactstrap";
import {ContextMenuTrigger} from "react-contextmenu";
import {Colxx} from "../../components/common/CustomBootstrap";


const DataListView = ({user, editUser}) => {
    return (
        <Colxx xxs="12" className="mb-3">
            <ContextMenuTrigger id="menu_id" data={user.uid}>
                <Card
                    className="d-flex flex-row"
                >
                    <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                        <div
                            className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-start min-width-zero align-items-lg-center">
                            <div className="w-30 w-sm-100 point-hover-effect" onClick={() => editUser(user)}>
                                <p className="list-item-heading mb-1 truncate">
                                    {user.name}
                                </p>
                            </div>
                            <div className="d-flex justify-content-between align-items-center w-100">
                                <p className="mb-1 text-muted text-small w-25 w-sm-100">
                                    {user.uid}
                                </p>
                                <p className="mb-1 text-muted text-small w-25 w-sm-100">
                                    {user.email}
                                </p>
                                <div className="d-flex w-15 w-sm-100 justify-content-end">
                                    {user.claime && user.claime.admin
                                        ? <Badge color="primary" pill>admin</Badge>
                                        : <Badge color="primary" pill>user</Badge>}
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </ContextMenuTrigger>
        </Colxx>
    );
};

export default DataListView;
