import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import React from "react";

const DeleteAccountModal = ({modal, toggle, onAccept, account}) => {
    return (
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                <IntlMessages id="treasures.delete"/>
            </ModalHeader>
            <ModalBody>
                Do you really want delete {account}'s account?
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => {
                    onAccept();
                    toggle();
                }}>
                    Delete
                </Button>{" "}
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};
export default DeleteAccountModal;