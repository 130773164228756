import {
    TREASURES_GET_LIST,
    TREASURES_GET_LIST_SUCCESS,
    TREASURES_GET_LIST_ERROR,
    TREASURES_ADD_ITEM,
    TREASURES_ADD_ITEM_SUCCESS,
    TREASURES_ADD_ITEM_ERROR,
    TREASURES_EDIT_ITEM,
    TREASURES_EDIT_ITEM_SUCCESS,
    TREASURES_EDIT_ITEM_ERROR,
    TREASURES_DELETE_ITEM,
    TREASURES_DELETE_ITEM_SUCCESS,
    TREASURES_DELETE_ITEM_ERROR,
} from '../actions';

export const getAllTreasures = (isAdmin, uid) => ({
    type: TREASURES_GET_LIST,
    payload: {isAdmin, uid}
});

export const getAllTreasuresSuccess = (treasures) => ({
    type: TREASURES_GET_LIST_SUCCESS,
    payload: treasures
});

export const getAllTreasuresError = (error) => ({
    type: TREASURES_GET_LIST_ERROR,
    payload: error
});

export const addTreasure = (treasure, history) => ({
    type: TREASURES_ADD_ITEM,
    payload: {treasure, history}
});

export const addTreasureSuccess = (treasure) => ({
    type: TREASURES_ADD_ITEM_SUCCESS,
    payload: treasure
});

export const addTreasureError = (error) => ({
    type: TREASURES_ADD_ITEM_ERROR,
    payload: error
});

export const editTreasure = (treasure, history) => ({
    type: TREASURES_EDIT_ITEM,
    payload: {treasure, history}
});

export const editTreasureSuccess = (treasure) => ({
    type: TREASURES_EDIT_ITEM_SUCCESS,
    payload: treasure
});

export const editTreasureError = (error) => ({
    type: TREASURES_EDIT_ITEM_ERROR,
    payload: error
});

export const deleteTreasure = (uid, history) => ({
    type: TREASURES_DELETE_ITEM,
    payload: {uid, history}
});

export const deleteTreasureSuccess = (uid) => ({
    type: TREASURES_DELETE_ITEM_SUCCESS,
    payload: uid
});

export const deleteTreasureError = (error) => ({
    type: TREASURES_DELETE_ITEM_ERROR,
    payload: error
});

