import React, {Component, Fragment} from "react";
import {Row} from "reactstrap";

import DataListView from "../../../containers/accounts/DataListView";
import ListPageHeading from "../../../containers/accounts/ListPageHeading";
import AddNewModal from "../../../containers/accounts/AddNewModal";
import {withRouter} from "react-router-dom";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {getAllUsers} from "../../../redux/accounts/actions";

import {createUserCloud, updateUserCloud, deleteUserCloud} from "../../../redux/accounts/saga"


class AccountListPages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            isLoading: false,
            items: [],
            editUser: null,
        };
    }

    componentDidMount() {
        const {getAllUsers} = this.props;
        getAllUsers();
    }

    toggleModal = () => {
        const {modalOpen, editUser} = this.state;
        this.setState({
            editUser: modalOpen === true ? null : editUser,
            modalOpen: !this.state.modalOpen
        });
    };

    editUser = (user) => {
        this.setState({editUser: user}, () => this.toggleModal())
    };

    createUser = (user) => {
        createUserCloud(user).then(res => res && this.toggleModal());
    };

    updateUser = (user, uid) => {
        user.uid = uid;
        updateUserCloud(user).then(res => res && this.toggleModal())
    };

    deleteUser = (uid) => {
        deleteUserCloud(uid).then(res => res && this.toggleModal())
    };

    render() {
        const {
            modalOpen,
            editUser
        } = this.state;

        const {match, users, loading} = this.props;

        return (
            <Fragment>
                <div className="disable-text-selection">
                    <ListPageHeading
                        heading="menu.data-list"
                        match={match}
                        toggleModal={this.toggleModal}
                    />
                    {modalOpen && <AddNewModal
                        modalOpen={modalOpen}
                        toggleModal={this.toggleModal}
                        user={editUser}
                        createUser={this.createUser}
                        editUser={this.updateUser}
                        deleteUser={this.deleteUser}
                    />}
                    {loading
                        ? <div className="loading"/>
                        : <Row>
                            {users && users.map(product => (
                                <DataListView
                                    key={product.uid}
                                    user={product}
                                    editUser={this.editUser}
                                />
                            ))}
                        </Row>}
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = ({accounts}) => {
    const {users, loading} = accounts;
    return {
        users,
        loading
    };
};

export default withRouter(
    injectIntl(
        connect(
            mapStateToProps,
            {
                getAllUsers
            }
        )(AccountListPages)
    ))
;

