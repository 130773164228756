import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {firebase} from '../../helpers/Firebase';

import {
    LOGIN_USER,
    REGISTER_USER,
    LOGOUT_USER,
    LOGIN_USER_GOOGLE,
    FORGET_PASSWORD,
    GET_USER_CLAIMS,
    GET_CURRENTLY_USER
} from '../actions';

import {
    loginUserSuccess,
    registerUserSuccess,
    getUserClaimsSuccess,
    setUserError,
    loginUserError,
    setUserMessage
} from './actions';


export const getUserTokenAsync = async () =>
    await new Promise(function (resolve, reject) {
        firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
                firebase.auth().currentUser.getIdTokenResult().then(data => {
                    resolve(data.claims)
                })
            } else {
                reject("Object not created");
            }
        });
    })
        .then(token => token)
        .catch(error => error);

const sendEmailVerification = async (user) =>
    await user.sendEmailVerification()
        .then(result => true)
        .catch(error => error);


function* getUserClaims({payload}) {
    try {
        const token = yield call(getUserTokenAsync);
        if (!token.message) {
            yield put(getUserClaimsSuccess(token[payload]));
        }
    } catch (error) {
        console.log('Get user role error:', error)
    }
}

const loginWithEmailPasswordAsync = async (email, password) =>
    await firebase.auth().signInWithEmailAndPassword(email, password)
        .then(authUser => authUser)
        .catch(error => error);

function* loginWithEmailPassword({payload}) {
    const {email, password} = payload.user;
    const {history} = payload;
    try {
        const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
        if (!loginUser.message) {

            if (loginUser.user.emailVerified) {
                const userFromDB = yield call(getUserByIdAsync, loginUser.user.uid);
                if (!userFromDB.message) {
                    const token = yield call(getUserTokenAsync);
                    if (!token.message) {
                        yield put(getUserClaimsSuccess(token.admin));
                        yield put(loginUserSuccess({
                            uid: loginUser.user.uid,
                            ...userFromDB.data()
                        }));
                        history.push('/');
                    }
                }
            } else {
                yield put(setUserMessage(''));
                yield put(setUserError('Please verify your email.'));
            }

        } else {
            yield put(setUserError(loginUser.message));
        }
    } catch (error) {
        yield put(setUserError(error));
    }
}

const getUserByIdAsync = async (uid) =>
    await firebase.firestore().collection("users").doc(uid).get()
        .then(response => response)
        .catch(error => error);

const loginWithGoogleAsync = async (provider) =>
    await firebase.auth().signInWithPopup(provider)
        .then(authUser => authUser)
        .catch(error => error);

function* loginWithGoogle({payload}) {
    const provider = new firebase.auth.GoogleAuthProvider();
    const {history} = payload;
    try {
        const loginUser = yield call(loginWithGoogleAsync, provider);
        if (!loginUser.message) {


            const createdUser = yield call(createUserAsync, loginUser.user.uid, {
                email: loginUser.user.email, name: loginUser.user.displayName
            });

            if (!createdUser.message) {

                const userFromDB = yield call(getUserByIdAsync, loginUser.user.uid);

                if (!userFromDB.message) {
                    const token = yield call(getUserTokenAsync);
                    if (!token.message) {
                        yield put(getUserClaimsSuccess(token.admin));
                    }
                    yield put(loginUserSuccess({
                        uid: loginUser.user.uid,
                        ...userFromDB.data()
                    }));
                    history.push('/');
                }

            }
        } else {
            yield put(setUserError(loginUser.message));
        }
    } catch (error) {
        yield put(setUserError(error));
    }
}

const registerWithEmailPasswordAsync = async (email, password) =>
    await firebase.auth().createUserWithEmailAndPassword(email, password)
        .then(authUser => authUser)
        .catch(error => error);

const createUserAsync = async (uid, user) =>
    await new Promise(function (resolve, reject) {
        const docRef = firebase.firestore().collection("users").doc(uid);
        docRef.set({...user});
        if (docRef && docRef.id) {
            resolve(docRef.id)
        } else {
            reject("Object not created");
        }
    })
        .then(authUser => authUser)
        .catch(error => error);

function* registerWithEmailPassword({payload}) {
    const {email, password, name} = payload.user;
    const {history} = payload;
    try {
        const registerUser = yield call(registerWithEmailPasswordAsync, email, password);
        if (!registerUser.message) {
            const createdUser = yield call(createUserAsync, registerUser.user.uid, {
                email, name
            });
            if (!createdUser.message) {
                const emailVerification = yield call(sendEmailVerification, registerUser.user);
                if (emailVerification) {
                    yield put(registerUserSuccess());
                    history.push('/');
                }
            }
        } else {
            yield put(setUserError(registerUser.message));
        }
    } catch (error) {
        yield put(setUserError(error));
    }
}

export const getCurrentlyUserAsync = async () =>
    await new Promise(function (resolve, reject) {
        firebase.auth().onAuthStateChanged(function (user) {
            if (user && user.emailVerified) {
                resolve({
                    email: user.email,
                    uid: user.uid,
                })
            } else {
                reject({message: ''})
            }
        });
    })
        .then(authUser => authUser)
        .catch(error => error);


function* getCurrentlyUser({payload}) {
    const {history} = payload;
    try {
        const loginUser = yield call(getCurrentlyUserAsync);
        if (!loginUser.message) {
            const userFromDB = yield call(getUserByIdAsync, loginUser.uid);
            if (!userFromDB.message) {
                const token = yield call(getUserTokenAsync);
                if (!token.message) {
                    yield put(getUserClaimsSuccess(token.admin));
                }
                yield put(loginUserSuccess({
                    uid: loginUser.uid,
                    ...userFromDB.data()
                }));
                history.push(history.location.pathname);
            }
        } else {
            yield put(loginUserError(loginUser.message));
        }
    } catch (error) {
        yield put(loginUserError(error));
    }
}

const forgotPasswordAsync = async (email) =>
    await new Promise(function (resolve, reject) {
        firebase.auth().sendPasswordResetEmail(email).then(() => {
            resolve(true)
        }).catch(err => reject(err))
    })
        .then(emailSent => emailSent)
        .catch(error => error);


function* forgotPassword({payload}) {
    const {email, history} = payload;
    try {
        const forgetPass = yield call(forgotPasswordAsync, email);
        if (!forgetPass.message) {
            history.push('/');
        } else {
            yield put(setUserError(forgetPass.message));
        }
    } catch (error) {
        yield put(setUserError(error));
    }
}

const logoutAsync = async (history) => {
    await firebase.auth().signOut().then(authUser => authUser).catch(error => error);
    history.push('/')
};

function* logout({payload}) {
    const {history} = payload;
    try {
        yield call(logoutAsync, history);
    } catch (error) {
    }
}

export const updateCurrentUser = (uid, user) => {
    return new Promise(function (resolve, reject) {
        const docRef = firebase.firestore().collection("users").doc(uid);
        docRef.update({...user});
        if (docRef && docRef.id) {
            resolve(docRef.id)
        } else {
            reject("Object not created");
        }
    });
};

export const verifyPasswordResetCode = (code) =>
    firebase.auth().verifyPasswordResetCode(code);

export const applyActionCode = (code) =>
    firebase.auth().applyActionCode(code);

export const confirmPasswordReset = (code, newPassword) =>
    firebase.auth().confirmPasswordReset(code, newPassword);

export function* watchGetUserClaims() {
    yield takeEvery(GET_USER_CLAIMS, getUserClaims);
}

export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

export function* watchLoginGoogle() {
    yield takeEvery(LOGIN_USER_GOOGLE, loginWithGoogle)
}

export function* watchGetCurrentlyUser() {
    yield takeEvery(GET_CURRENTLY_USER, getCurrentlyUser);
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

export function* watchForgotPassword() {
    yield takeEvery(FORGET_PASSWORD, forgotPassword)
}

export default function* rootSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
        fork(watchRegisterUser),
        fork(watchLoginGoogle),
        fork(watchForgotPassword),
        fork(watchGetUserClaims),
        fork(watchGetCurrentlyUser)
    ]);
}
